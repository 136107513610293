<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content-width">
      <!-- Course -->
      <div class="col-span-1">
        <Select
          v-bind:data="data"
          v-bind:typeSelected="this.filtered.type"
          v-bind:yearSelected="this.filtered.year"
          v-bind:semesterSelected="this.filtered.sems"
          v-bind:prevCourse="this.filtered.prev"
          v-for="data in yearCourses"
          :key="data.id"
          @changeSelected="changeSelected"
          @click="changeSelectedCourse"
        />
      </div>

      <!-- Header -->
      <div class="manage-header">
        <div class="row">
          <p class="table-header-with-button">ข้อมูลห้องสอบ</p>
          <div class="mr-auto"></div>

          <!-- Program filter -->
          <p class="my-auto">หลักสูตร</p>
          <select v-model="filtered.program" @change="changeSelectedCourse()" class="select-bar">
            <option v-for="program in programs" :key="program.id" :value="program.code">{{ program.name }}</option>
          </select>
        </div>

        <div class="row">
          <!-- Download: exam file -->
          <div v-if="role !== 'STD'" class="row">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 my-auto" fill="none" viewBox="0 0 24 24" stroke="black"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/></svg>
            <button @click="downloadFile('std')" class="file-button ml-4">ใบเซ็นชื่อนักศึกษา</button>
            <button @click="downloadFile('stf')" class="file-button ml-4">ใบเซ็นชื่ออาจารย์</button>
          </div>

          <!-- Exam type filter -->
          <p class="ml-auto my-auto">ประเภทการสอบ</p>
          <select v-model="filtered.exam_type" @change="changeSelectedCourse()" class="select-bar my-auto">
            <option value="01">สอบนำเสนอโครงงาน</option>
            <option value="02">สอบโปสเตอร์</option>
          </select>
        </div>

        <!-- Table loading -->
        <div v-if="table_loading" class="mt-8">
          <div class="loader m-auto"></div>
        </div>
        <div v-else-if="filtered.exams.length > 0">
          <!-- Student list (Table) -->
          <table>
            <!-- Table header -->
            <thead>
              <tr>
                <th class="w-1/12">วันที่จัดสอบ</th>
                <th class="w-2/12">เวลาที่จัดสอบ</th>
                <th class="w-1/12">ห้องสอบ</th>
                <th class="w-3/12">กรรมการสอบ</th>
                <th class="w-4/12">หัวข้อโครงงาน</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <!-- Horizontal line -->
              <tr><td colspan="7"><hr></td></tr>
              <!-- Data -->
              <tr v-for="exam in filtered.exams" :key="exam.id">
                <td>{{ exam.date }}</td>
                <td>{{ exam.start_time }} - {{ exam.end_time }}</td>
                <td>{{ exam.room }}</td>
                <td>
                  <div v-for="ex in exam.examiners" :key="ex.id">{{ ex.name }}</div>
                </td>
                <td class="text-left">{{ exam.project.topic_th }}<br>({{ exam.project.topic_en }})</td>
                <td v-if="exam.is_examiner" class="text-right">
                  <router-link :to="'/exam/course/' + exam.course.id + '/exam_type/' + exam.exam_type_id + '/type/' + exam.examtype_type + '/project/' + exam.project.id + '/'">
                    <button class="view-button mx-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white">
                        <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Not Found -->
        <div v-else class="text-not-found">ไม่พบข้อมูลที่สอดคล้องกับตัวกรองข้อมูลที่เลือก</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import Select from "@/components/Select"
import { changeProgramName, changeProgramByType, yearCourseSelect } from "../mixins/other"

export default {
  name: "ExamManage",
  components: {
    NavBar,
    Select,
  },
  data() {
    return {
      loading: true,
      table_loading: true,
      courses: null,
      projects: null,
      programs: [],
      years: [],
      yearCourses: [],
      filtered: {
        exam_type: "01"
      },
      exams: [],
      role: null,
      is_examiner: false
    }
  },
  async created() {
    document.title = "ห้องสอบ | Carbon"
    this.role = this.$store.getters.payload.role
    await this.setSelected()
    let { years, yearCourses } = yearCourseSelect(this.courses)
    this.years = years
    this.yearCourses = yearCourses
    await this.changeSelectedCourse()
    this.programs = await this.$store.dispatch("getProgram")
    this.programs = changeProgramName(changeProgramByType(this.filtered.type))
    this.loading = false
    this.table_loading = false
  },
  methods: {
    async setSelected() {
      this.courses = await this.$store.dispatch("getCourse")
      this.courses = this.courses.filter((course) => { return parseInt(course.year) > 2563 })
      this.courses.sort(function (c1, c2) { return c1.code - c2.code })
      this.courses.sort(function (c1, c2) { return c2.year - c1.year })
      this.filtered.course = this.courses[0]
      this.filtered.year = this.filtered.course.year
      this.filtered.type = this.filtered.course.type
      this.filtered.sems = (this.courses[0].type == 'PJ') ? null : this.filtered.course.semester
      this.filtered.prev = null
      this.filtered.program = 'IT'
    },
    changeSelected(year, type, sems, prev) {
      this.filtered.year = year
      this.filtered.type = type
      this.filtered.sems = sems
      this.filtered.prev = prev
      this.programs = changeProgramName(changeProgramByType(this.filtered.type))
      if (this.filtered.type == 'IS') {
        this.filtered.program = 'DTI'
      } else {
        this.filtered.program = 'IT'
      }
    },
    async examFilter() {
      this.table_loading = true
      this.filtered.exams = []
      this.exams = []
      let course = await this.$store.dispatch("getCourseById", this.filtered.course[0])
      this.filtered.exams = await this.$store.dispatch("getExam", { course: course.id, semester: course.semester, year: parseInt(course.year), role: this.role, email: this.$store.getters.payload.email, code: this.$store.getters.payload.code, exam_type: this.filtered.exam_type })
      this.table_loading = false
    },
    async changeSelectedCourse() {
      this.filtered.course = []
      let courseList = []
      this.courses.filter((course) => {
        if (course.year == this.filtered.year && course.type == this.filtered.type && course.semester == this.filtered.sems && this.filtered.type == 'IS') {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && this.filtered.type == 'CP') {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && course.prev_course == null && this.filtered.type == 'PJ' && this.filtered.prev == null) {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && course.prev_course != null && this.filtered.type == 'PJ' && this.filtered.prev != null) {
          courseList.push([course.id, course.program_code])
        }
      })

      courseList.filter((course) => {
        if (this.filtered.program == course[1])
          this.filtered.course.push(course[0])
      })

      if (this.filtered.course.length > 0) {
        await this.examFilter()
      } else {
        this.filtered.exams = []
      }
    },
    async downloadFile(type) {
      if (this.filtered.exams.length == 0) {
        alert("ไม่พบข้อมูลห้องสอบที่สอดคล้องกับรายวิชาและหลักสูตรที่เลือกภายในระบบ")
      } else {
        this.filtered.exams[0].type = type
        await this.$store.dispatch("exportSign", this.filtered.exams)
      }
    },
  },
}
</script>